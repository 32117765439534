import { isObject } from '@/utils/is'
import {NEED_LOGOUT_CODES, OK_CODE, SYSTEM_OK_COKE} from '@/constants/api_code_const'

// 请求结果正常
export function isResOK(res) {
  if (isObject(res)) {
    return res.code === OK_CODE
  } else {
    return false
  }
}

// 是否需要自动登出
export function isNeedLogout(res) {
  if (isObject(res)) {
    return NEED_LOGOUT_CODES.includes(res.code)
  } else {
    return false
  }
}

// 调用系统接口
export function isSysResOK(res) {
  if (isObject(res)) {
    return res.code === SYSTEM_OK_COKE
  } else {
    return false
  }
}
