const toString = Object.prototype.toString

export function is(val, type) {
  return toString.call(val) === `[object ${type}]`
}

export function isObject(val) {
  return val !== null && is(val, 'Object')
}

export function isEmpty(val) {
  if (isArray(val) || isString(val)) {
    return val.length === 0
  }

  if (val instanceof Map || val instanceof Set) {
    return val.size === 0
  }

  if (isObject(val)) {
    return Object.keys(val).length === 0
  }

  return false
}

export function isNull(val) {
  return val === null
}

export function isNumber(val) {
  return is(val, 'Number')
}

export function isString(val) {
  return is(val, 'String')
}

export function isArray(val) {
  return val && Array.isArray(val)
}
