import request from '@/utils/request'

export function getAdminList(params) {
  return request({
    url: process.env.VUE_APP_ALI_API + '/sys/admin/page',
    method: 'get',
    params
  })
}

export function deleteAdmin(id) {
  return request({
    url: process.env.VUE_APP_ALI_API + '/sys/admin/del?ids=' + id,
    method: 'post',
  })
}

export function addAdmin(params) {
  return request({
    url: process.env.VUE_APP_ALI_API + '/sys/admin/add',
    method: 'post',
    data: params
  })
}

export function editAdmin(params) {
  return request({
    url: process.env.VUE_APP_ALI_API + '/sys/admin/edit',
    method: 'post',
    data: params
  })
}

export function getOrgList(params) {
  return request({
    url: process.env.VUE_APP_ZHEN_API + '/org/queryOrgList',
    method: 'post',
    data: params
  })
}

export function getOrgDeptList(params) {
  return request({
    url: process.env.VUE_APP_ZHEN_API + '/depart/queryDepartList',
    method: 'post',
    data: params
  })
}

export function getDeptDetail(params) {
  return request({
    url: process.env.VUE_APP_ZHEN_API + '/depart/queryDepartInfo',
    method: 'post',
    data: params
  })
}

export function queryDepartPostList(params) {
  return request({
    url: process.env.VUE_APP_ZHEN_API + '/depart/post/queryDepartPostList',
    method: 'post',
    data: params
  })
}



