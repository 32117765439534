// 获取登录用户所在公司所有用户列表
import {getCurrentUser, getOrgUserList} from "@/api/user";
import {isResOK, isSysResOK} from "@/api/response";
import {getOrgDeptList, getOrgList} from "@/api/admin";
import store from "@/store";

export function findUserList() {
    return new Promise(resolve => {
        const orgListIds = []
        orgListIds.push(store.getters.user.organizationId)
        // store.getters.user.organizationList.forEach(organization => {
        //     organization.organizationIdList.forEach(orgId => {
        //         orgListIds.push(orgId)
        //     })
        // })
        const orgPromises = getOrgDepartmentPromises(orgListIds)
        Promise.all(orgPromises).then(result => {
            const departmentIds = []
            result.forEach(item => {
                item.forEach(obj => {
                    departmentIds.push(obj.id)
                })
            })
            Promise.all(getUserListPromises(departmentIds)).then(r => {
                const userList = []
                r.forEach(arr => {
                    if (arr.length > 0) {
                        arr.forEach(user => {
                            userList.push(user)
                        })
                    }
                })
                resolve(userList)
            })
        })
    })
}

// 获取施工队名单
export function findTeamUserList() {
    return new Promise(resolve => {
        const orgListIds = []
        orgListIds.push(store.getters.user.organizationId)
        // store.getters.user.organizationList.forEach(organization => {
        //     organization.organizationIdList.forEach(orgId => {
        //         orgListIds.push(orgId)
        //     })
        // })
        const orgPromises = getOrgDepartmentPromises(orgListIds)
        Promise.all(orgPromises).then(result => {
            const departmentIds = []
            result.forEach(item => {
                item.forEach(obj => {
                    if (obj.departName === '工程部') {
                        departmentIds.push(obj.id)
                    }
                })
            })
            Promise.all(getUserListPromises(departmentIds)).then(r => {
                const userList = []
                r.forEach(arr => {
                    if (arr.length > 0) {
                        arr.forEach(user => {
                            userList.push(user)
                        })
                    }
                })
                resolve(userList)
            })
        })
    })
}

// 获取运维部门名单
export function findOperationUserList() {
    return new Promise(resolve => {
        const orgListIds = []
        orgListIds.push(store.getters.user.organizationId)
        // store.getters.user.organizationList.forEach(organization => {
        //     organization.organizationIdList.forEach(orgId => {
        //         orgListIds.push(orgId)
        //     })
        // })
        const orgPromises = getOrgDepartmentPromises(orgListIds)
        Promise.all(orgPromises).then(result => {
            const departmentIds = []
            result.forEach(item => {
                item.forEach(obj => {
                    if (obj.departName === '运维部') {
                        departmentIds.push(obj.id)
                    }
                })
            })
            Promise.all(getUserListPromises(departmentIds)).then(r => {
                const userList = []
                r.forEach(arr => {
                    if (arr.length > 0) {
                        arr.forEach(user => {
                            userList.push(user)
                        })
                    }
                })
                resolve(userList)
            })
        })
    })
}


function getOrgDepartmentPromises(orgListIds) {
    const orgPromises = []
    orgListIds.forEach(orgId => {
        orgPromises.push(new Promise((resolve) => {
            getOrgDeptList({orgId: orgId}).then(res => {
                if (isSysResOK(res)) {
                    resolve(res.result)
                }
            })
        }))
    })
    return orgPromises
}

function getUserListPromises(departmentList) {
    const userListPromises = []
    departmentList.forEach(deptId => {
        userListPromises.push(new Promise((resolve) => {
            getOrgUserList({deptId: deptId}).then(res => {
                if (isResOK(res)) {
                    resolve(res.data)
                }
            })
        }))
    })
    return userListPromises
}


export function getUrlParams(url) {
    if (url.indexOf('?') === -1) {
        return {}
    }
    // 通过 ? 分割获取后面的参数字符串
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
        // 再通过 = 将每一个参数分割为 key:value 的形式
        let arr = paramsArr[i].split('=')
        obj[arr[0]] = arr[1];
    }
    return obj
}
