import request from '@/utils/request'

export function getUserList(params) {
  return request({
    url: process.env.VUE_APP_ALI_API + '/sys/user/page',
    method: 'get',
    params
  })
}
export function getUserInfo(params) {
  return request({
    url: process.env.VUE_APP_ALI_API + '/sys/user/get',
    method: 'get',
    params
  })
}

export function deleteUser(id) {
  return request({
    url: process.env.VUE_APP_ALI_API + '/sys/user/del?ids=' + id,
    method: 'post',
  })
}

export function addUser(params) {
  return request({
    url: process.env.VUE_APP_ALI_API + '/sys/user/add',
    method: 'post',
    data: params
  })
}

export function editUser(params) {
  return request({
    url: process.env.VUE_APP_ALI_API + '/sys/user/edit',
    method: 'post',
    data: params
  })
}
export function exportUser(params) {
  return request({
    url: process.env.VUE_APP_ALI_API + '/sys/user/export',
    method: 'post',
    data: params
  })
}

export function getOrgList(params) {
  return request({
    url: process.env.VUE_APP_ZHEN_API + '/org/queryOrgList',
    method: 'post',
    data: params
  })
}

export function getOrgDeptList(params) {
  return request({
    url: process.env.VUE_APP_ZHEN_API + '/depart/queryDepartList',
    method: 'post',
    data: params
  })
}

export function getDeptDetail(params) {
  return request({
    url: process.env.VUE_APP_ZHEN_API + '/depart/queryDepartInfo',
    method: 'post',
    data: params
  })
}

export function queryDepartPostList(params) {
  return request({
    url: process.env.VUE_APP_ZHEN_API + '/depart/post/queryDepartPostList',
    method: 'post',
    data: params
  })
}
export function getSmsCode(params) {
  return request({
    url: process.env.VUE_APP_ALI_API + '/sys/user/sendSmsCode',
    method: 'post',
    data: params
  })
}

// 获取当前登录用户信息
// 参数 auth-token
export function getCurrentUser(params) {
  return request({
    url: process.env.VUE_APP_AC_ALI_API + '/sys/admin/getCurrent',
    method: 'get',
    params
  })
}

// 获取部门人员列表
// 参数 deptId 部门id
export function getOrgUserList(params) {
  return request({
    url: process.env.VUE_APP_AC_ALI_API + '/sys/user/listDeptUser',
    method: 'get',
    params
  })
}





