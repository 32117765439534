import request from '@/utils/request'

// 查询项目类型列表
export function listProjectType(query) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/project_type/list',
    method: 'get',
    params: query
  })
}

// 查询项目类型详细
export function getProjectType(id) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/project_type/' + id,
    method: 'get'
  })
}

// 新增项目类型
export function addProjectType(data) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/project_type',
    method: 'post',
    data: data
  })
}

// 修改项目类型
export function updateProjectType(data) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/project_type',
    method: 'put',
    data: data
  })
}

// 删除项目类型
export function delProjectType(id, data) {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/project_type/' + id,
    method: 'delete',
    data: data
  })
}

// 查询项目类型选项
export function getProjectTypeOptions() {
  return request({
    url: process.env.VUE_APP_BASE_API + '/api/business/project_type/options',
    method: 'get'
  })
}
