export const OK_CODE = 200
// 无效的 token
export const INVALID_TOKEN_CODE = 10003
// 用户被禁止登录
export const USER_LOCKED_CODE = 30002

export const USER_EFFECTIVE_TIME_CODE = 40009
// 需要登出的 codes
export const NEED_LOGOUT_CODES = [INVALID_TOKEN_CODE, USER_LOCKED_CODE, USER_EFFECTIVE_TIME_CODE]

export const SYSTEM_OK_COKE = 0

